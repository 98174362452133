import React, { useState } from 'react'
import i1 from "../../assets/images/new-arrival/s1.jpeg"
import i2 from "../../assets/images/new-arrival/s2.jpeg"
import i3 from "../../assets/images/new-arrival/s1.jpeg"
import i4 from "../../assets/images/new-arrival/s2.jpeg"
import i5 from "../../assets/images/new-arrival/s1.jpeg"
import i6 from "../../assets/images/new-arrival/s2.jpeg"
import i7 from "../../assets/images/new-arrival/s1.jpeg"
import i8 from "../../assets/images/new-arrival/s2.jpeg"
import i9 from "../../assets/images/new-arrival/s1.jpeg"
import i10 from "../../assets/images/new-arrival/s2.jpeg"
import i11 from "../../assets/images/new-arrival/s3.jpeg"
import i12 from "../../assets/images/new-arrival/s4.jpeg"
import i13 from "../../assets/images/new-arrival/s3.jpeg"
import i14 from "../../assets/images/new-arrival/s4.jpeg"
import i15 from "../../assets/images/new-arrival/s3.jpeg"
import DisplaySingleProduct from '../../components/DisplaySingleProduct'

export default function LatestProducts() {
    let imagedata = [i1, i2, i3, i4, i5,]
    let [selectedImg, setSelectedImg] = useState(0)
    return (
        <>
            <section className="product-tab-section section-padding bg-light">
                <div className="container">
                    <div className="text-center pb-3">
                        <h3 className="mb-0 h3 fw-bold">Latest Products</h3>
                        {/* <p className="mb-0 text-capitalize">The purpose of lorem ipsum</p> */}
                    </div>
                    {/* <div className="row">
                        <div className="col-auto mx-auto">
                            <div className="product-tab-menu table-responsive">
                                <ul className="nav nav-pills flex-nowrap" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" data-bs-toggle="pill" data-bs-target="#new-arrival" type="button">New
                                            Arrival</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" data-bs-toggle="pill" data-bs-target="#best-sellar" type="button">Best
                                            Sellar</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" data-bs-toggle="pill" data-bs-target="#trending-product"
                                            type="button">Trending</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" data-bs-toggle="pill" data-bs-target="#special-offer" type="button">Special
                                            Offer</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                    <hr />
                    <div className="tab-content tabular-product">
                        <div className="tab-pane fade show active " id="new-arrival">
                            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-5 g-4">
                                {
                                    imagedata && imagedata.map((val, ind) => {
                                        return (
                                            <div className="col">
                                                <div className="card" >
                                                    <div className="position-relative overflow-hidden">
                                                        <div
                                                            className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0 h-100">
                                                            <a href="#"><i className="bi bi-heart"></i></a>
                                                            <a href="#"><i className="bi bi-basket3"></i></a>
                                                            <a href="#" onClick={() => { setSelectedImg(val) }} data-bs-toggle="modal" data-bs-target="#latestproduct"><i
                                                                className="bi bi-zoom-in"></i></a>
                                                        </div>
                                                        {/* <a href="#" style={{background:"rgba(0,0,0,0.6)"}}> */}
                                                        <img src={val} className="card-img-top" height="350" alt="..." />
                                                        {/* </a> */}
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="product-info text-center">
                                                            <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                            <div className="ratings mb-1 h6">
                                                                <i className="bi bi-star-fill text-warning"></i>
                                                                <i className="bi bi-star-fill text-warning"></i>
                                                                <i className="bi bi-star-fill text-warning"></i>
                                                                <i className="bi bi-star-fill text-warning"></i>
                                                                <i className="bi bi-star-fill text-warning"></i>
                                                            </div>
                                                            <p className="mb-0 h6 fw-bold product-price">$49</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                                {/* <div className="col">
                                    <div className="card">
                                        <div className="ribban">New Season</div>
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i2} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i3} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i4} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i5} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i6} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i7} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i8} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i9} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i10} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div className="tab-pane fade show_ active_  " id="best-sellar">
                            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-5 g-4">
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i11} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i12} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i13} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="ribban bg-primary">New Fashion</div>
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i14} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src={i15} className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="tab-pane fade" id="trending-product">
                            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-5 g-4">
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src="assets/images/trending-product/01.png" className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src="assets/images/trending-product/02.png" className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="ribban bg-warning text-dark">New Season</div>
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="javascript:;">
                                                <img src="assets/images/trending-product/03.png" className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src="assets/images/trending-product/04.png" className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src="assets/images/trending-product/05.png" className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="special-offer">
                            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-4 row-cols-xxl-5 g-4">
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src="assets/images/special-offer/01.png" className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src="assets/images/special-offer/02.png" className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="ribban">50% Discount</div>
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src="assets/images/special-offer/03.png" className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src="assets/images/special-offer/04.png" className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="position-relative overflow-hidden">
                                            <div
                                                className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal"><i
                                                    className="bi bi-zoom-in"></i></a>
                                            </div>
                                            <a href="product-details.html">
                                                <img src="assets/images/special-offer/05.png" className="card-img-top" alt="..." />
                                            </a>
                                        </div>
                                        <div className="card-body">
                                            <div className="product-info text-center">
                                                <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                <div className="ratings mb-1 h6">
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                    <i className="bi bi-star-fill text-warning"></i>
                                                </div>
                                                <p className="mb-0 h6 fw-bold product-price">$49</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>

            <DisplaySingleProduct selectedImg={selectedImg} id="latestproduct"/>
        </>
    )
}

