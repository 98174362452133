import React from 'react'
import { Link } from 'react-router-dom'

export default function ResetPassword() {
    return (
        <>


            <section class="section-padding">
                <div className='container'>
                    <div className='d-flex flex-row justify-content-center align-items-center pb-3 registration'>
                        <h4>Reset Password</h4>
                    </div>
                    <div class="col-12 pt-4">
                        <p class="mb-0 rounded-0 w-100 text-lightbrown">If you do not have an account with us, please sign up at the <Link to="/registration/" class="text-brown">Sign Up</Link></p>
                    </div>

                    <div className='pt-4'>
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label ">Email address</label>
                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                            <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label">Password</label>
                            <input type="password" class="form-control" id="exampleInputPassword1" />
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label">New Password</label>
                            <input type="password" class="form-control" id="exampleInputPassword1" />
                        </div>

                    </div>
                    <div class="pt-2">
                        <Link to="/login/" class="text-content rounded-0 w-100 text-lightbrown"><i class="bi bi-arrow-left me-2"></i>Return to Login</Link>
                    </div>
                    {/* <div class="pt-2">
                        <Link to="/resetpassword/" class="text-content rounded-0 w-100 text-lightbrown"><i class="bi bi-lock me-2"></i>Forgot Password ?</Link>
                    </div> */}
                    <button className='btn mt-3 login-btn'>
                        Login
                    </button>
                </div>
            </section>
        </>
    )
}

