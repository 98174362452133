import React, { useEffect, useState } from 'react'

export default function DisplaySingleProduct(props) {
    let [image, setImage] = useState(0)
    useEffect(()=>{
        setImage(props.selectedImg)
    }, [props])
  return (
    <>
         <div class="modal fade" id={props.id} tabindex="-1">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content rounded-0">

                        <div class="modal-body">
                            <div class="row g-3">
                                <div class="col-12 col-xl-6">

                                    <div class="wrap-modal-slider">

                                        <div class="slider-for">
                                            <div>
                                                <img src={image} alt="" class="img-fluid" />
                                            </div>
                                            {/* <div>
                                                <img src={selectedImg} alt="" class="img-fluid"/>
                                            </div>
                                            <div>
                                                <img src={selectedImg} alt="" class="img-fluid"/>
                                            </div>
                                            <div>
                                                <img src={selectedImg} alt="" class="img-fluid"/>
                                            </div> */}
                                        </div>

                                        <div class="slider-nav mt-3">
                                            {/* <div>
                                                <img src={selectedImg} alt="" class="img-fluid"/>
                                            </div> */}
                                            {/* <div>
                                                <img src={selectedImg} alt="" class="img-fluid"/>
                                            </div>
                                            <div>
                                                <img src={selectedImg} alt="" class="img-fluid"/>
                                            </div>
                                            <div>
                                                <img src={selectedImg} alt="" class="img-fluid"/>
                                            </div> */}
                                        </div>

                                    </div>

                                </div>
                                <div class="col-12 col-xl-6">
                                    <div class="product-info">
                                        <h4 class="product-title fw-bold mb-1">Product Name</h4>
                                        <p class="mb-0">23.5 L Backpack FIND  (Black)</p>
                                        <div class="product-rating">
                                            <div class="hstack gap-2 border p-1 mt-3 width-content">
                                                <div><span class="rating-number">4.8</span><i class="bi bi-star-fill ms-1 text-success"></i></div>
                                                <div class="vr"></div>
                                                <div>162 Ratings</div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="product-price d-flex align-items-center gap-3">
                                            <div class="h4 fw-bold">$458</div>
                                            <div class="h5 fw-light text-muted text-decoration-line-through">$2089</div>
                                            <div class="h4 fw-bold text-danger">(70% off)</div>
                                        </div>
                                        <p class="fw-bold mb-0 mt-1 text-success">inclusive of all taxes</p>

                                        <div class="more-colors mt-3">
                                            <h6 class="fw-bold mb-3">More Colors</h6>
                                            <div class="d-flex align-items-center gap-2 flex-wrap">
                                                <div class="color-box bg-red"></div>
                                                <div class="color-box bg-primary"></div>
                                                <div class="color-box bg-yellow"></div>
                                                <div class="color-box bg-purple"></div>
                                                <div class="color-box bg-green"></div>
                                            </div>
                                        </div>

                                        <div class="size-chart mt-3">
                                            <h6 class="fw-bold mb-3">Select Size</h6>
                                            <div class="d-flex align-items-center gap-2 flex-wrap">
                                                <div class="">
                                                    <button type="button" class="rounded-0">XS</button>
                                                </div>
                                                <div class="">
                                                    <button type="button" class="rounded-0">S</button>
                                                </div>
                                                <div class="">
                                                    <button type="button" class="rounded-0">M</button>
                                                </div>
                                                <div class="">
                                                    <button type="button" class="rounded-0">L</button>
                                                </div>
                                                <div class="">
                                                    <button type="button" class="rounded-0">XL</button>
                                                </div>
                                                <div class="">
                                                    <button type="button" class="rounded-0">XXL</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="cart-buttons mt-3">
                                            <div class="buttons d-flex flex-column gap-3 mt-4">
                                                <a href="javascript:;" class="btn btn-lg btn-dark btn-ecomm px-5 py-3 flex-grow-1"><i
                                                    class="bi bi-basket2 me-2"></i>Add to Bag</a>
                                                <a href="javascript:;" class="btn btn-lg btn-outline-dark btn-ecomm px-5 py-3"><i
                                                    class="bi bi-suit-heart me-2"></i>Wishlist</a>
                                            </div>
                                        </div>
                                        <hr class="my-3" />
                                        <div class="product-share">
                                            <h6 class="fw-bold mb-3">Share This Product</h6>
                                            <div class="d-flex align-items-center gap-2 flex-wrap">
                                                <div class="">
                                                    <button type="button" class="btn-social bg-twitter"><i class="bi bi-twitter"></i></button>
                                                </div>
                                                <div class="">
                                                    <button type="button" class="btn-social bg-facebook"><i class="bi bi-facebook"></i></button>
                                                </div>
                                                <div class="">
                                                    <button type="button" class="btn-social bg-linkden"><i class="bi bi-linkedin"></i></button>
                                                </div>
                                                <div class="">
                                                    <button type="button" class="btn-social bg-youtube"><i class="bi bi-youtube"></i></button>
                                                </div>
                                                <div class="">
                                                    <button type="button" class="btn-social bg-pinterest"><i class="bi bi-pinterest"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
    </>
  )
}
