import React, { useEffect, useState } from 'react'
import i1 from "../../assets/images/sliders/s.webp"
import i2 from "../../assets/images/sliders/s2.jpeg"
import i3 from "../../assets/images/sliders/s.webp"
import i4 from "../../assets/images/sliders/s2.jpeg"
import i5 from "../../assets/images/sliders/s.webp"
import i6 from "../../assets/images/sliders/s2.jpeg"
import i7 from "../../assets/images/sliders/s.webp"
import i8 from "../../assets/images/sliders/s2.jpeg"

import Slider from "react-slick";
import '../../assets/css/slick.css'
import '../../assets/css/slick-theme.css'
import DisplaySingleProduct from '../../components/DisplaySingleProduct'

export default function FeatureProduct(props) {
    const images = [i1, i2, i3, i4, i5, i6, i7, i8];
    let [selectedImg, setSelectedImg] = useState(0)

    let [settings, setSettings] = useState([])
    window.addEventListener('resize', () => {
        setTimeout(() => {
            let widthData = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

            setSettings({
                dots: true,
                arrows: true,
                infinite: true,
                speed: 300,
                slidesToShow: widthData <= 480 ? 1 : widthData > 480 && widthData <= 768 ? 2 : widthData >= 769 && widthData <= 1024? 3 : widthData >= 1025 ? 4 : 5,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 1000,
                nextArrow: (
                    <button type='button' class='slick-next pull-right me-4' style={{ height: "black" }}><i class='bi bi-chevron-right' ></i></button>


                ),

                prevArrow: (
                    <button type='button' class='slick-prev pull-left ms-4 '><i class='bi bi-chevron-left'></i></button>

                ),

                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            dots: true,
                            arrows: true,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            autoplay: true,
                            autoplaySpeed: 1000,
                        }
                    },
                    {
                        breakpoint: 769,
                        settings: {
                            dots: true,
                            arrows: true,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            autoplay: true,
                            autoplaySpeed: 1000,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            dots: true,
                            arrows: true,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            autoplay: true,
                            autoplaySpeed: 1000,
                        }
                    },
                    {
                        breakpoint: 320,
                        settings: {
                            dots: true,
                            arrows: true,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplay: true,
                            autoplaySpeed: 1000,
                        }
                    },

                ],

                // <div>
                //     <div className="next-slick-arrow">
                //         <svg xmlns="http://www.w3.org/2000/svg" stroke="black" height="24" viewBox="0 -960 960 960" width="24"><path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" /></svg>
                //     </div>
                // </div>
                // <div>
                //     <div className="next-slick-arrow rotate-180" style={{ transform: "rotate(180deg)" }}>
                //         <svg xmlns="http://www.w3.org/2000/svg" stroke="black" height="24" viewBox="0 -960 960 960" width="24"><path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" /></svg>
                //     </div>
                // </div>

            })
        }, 200)
    });

    useEffect(() => {
        setTimeout(() => {
            let widthData = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

            setSettings({
                dots: true,
                arrows: true,
                infinite: true,
                speed: 300,
                slidesToShow: widthData <= 480 ? 1 : widthData > 480 && widthData <= 768 ? 2 : widthData >= 769 && widthData <= 1024? 3 : widthData >= 1025 ? 4 : 5,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 1000,
                nextArrow: (
                    <button type='button' class='slick-next pull-right me-4' style={{ height: "black" }}><i class='bi bi-chevron-right' ></i></button>


                ),

                prevArrow: (
                    <button type='button' class='slick-prev pull-left ms-4'><i class='bi bi-chevron-left'></i></button>

                ),

                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            dots: true,
                            arrows: true,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            autoplay: true,
                            autoplaySpeed: 1000,
                        }
                    },
                    {
                        breakpoint: 769,
                        settings: {
                            dots: true,
                            arrows: true,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 3,
                            slidesToScroll: 1,
                            autoplay: true,
                            autoplaySpeed: 1000,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            dots: true,
                            arrows: true,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            autoplay: true,
                            autoplaySpeed: 1000,
                        }
                    },
                    {
                        breakpoint: 320,
                        settings: {
                            dots: true,
                            arrows: true,
                            infinite: true,
                            speed: 300,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            autoplay: true,
                            autoplaySpeed: 1000,
                        }
                    },

                ],

                // <div>
                //     <div className="next-slick-arrow">
                //         <svg xmlns="http://www.w3.org/2000/svg" stroke="black" height="24" viewBox="0 -960 960 960" width="24"><path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" /></svg>
                //     </div>
                // </div>
                // <div>
                //     <div className="next-slick-arrow rotate-180" style={{ transform: "rotate(180deg)" }}>
                //         <svg xmlns="http://www.w3.org/2000/svg" stroke="black" height="24" viewBox="0 -960 960 960" width="24"><path d="m242-200 200-280-200-280h98l200 280-200 280h-98Zm238 0 200-280-200-280h98l200 280-200 280h-98Z" /></svg>
                //     </div>
                // </div>

            })
        }, 200)

    }, [])
    return (
        <>
            <section className="section-padding">
                <div className="container">
                    <div className="text-center pb-3">
                        <h3 className="mb-0 h3 fw-bold">{props.heading}</h3>
                        <p className="mb-0 text-capitalize">{props.subHeading}</p>
                    </div>
                    <div className='row d-flex '>
                        {settings &&
                            <Slider {...settings}>
                                {images.map((image, index) => {

                                    return (
                                        <div className="col">
                                            <div className="card">
                                                <div className="position-relative overflow-hidden">
                                                    <div
                                                        className={`product-options  d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0 h-100`}>
                                                        <a href="#"><i className="bi bi-heart"></i></a>
                                                        <a href="#"><i className="bi bi-basket3"></i></a>
                                                        <a href="#" onClick={() => { setSelectedImg(image) }} data-bs-toggle="modal" data-bs-target="#featureprodct"><i
                                                            className="bi bi-zoom-in"></i></a>
                                                    </div>
                                                    {/* <a href="#" style={{background:"rgba(0,0,0,0.6)"}}> */}
                                                    <img src={image} className="card-img-top" height="300" alt="..." />
                                                    {/* </a> */}
                                                </div>
                                                <div className="card-body">
                                                    <div className="product-info text-center">
                                                        <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                        <div className="ratings mb-1 h6">
                                                            <i className="bi bi-star-fill text-warning"></i>
                                                            <i className="bi bi-star-fill text-warning"></i>
                                                            <i className="bi bi-star-fill text-warning"></i>
                                                            <i className="bi bi-star-fill text-warning"></i>
                                                            <i className="bi bi-star-fill text-warning"></i>
                                                        </div>
                                                        <p className="mb-0 h6 fw-bold product-price">$49</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        }
                    </div>

                </div>
            </section>

            <DisplaySingleProduct selectedImg={selectedImg} id="featureprodct" />
        </>
    )
}


{/* <div key={index} className="card" style={{ width: "150px" }}>
                                            <div className="position-relative overflow-hidden">
                                                <div className="product-options d-flex align-items-center justify-content-center gap-2 mx-auto position-absolute bottom-0 start-0 end-0">
                                                    <a href="javascript:;"><i className="bi bi-heart"></i></a>
                                                    <a href="javascript:;"><i className="bi bi-basket3"></i></a>
                                                    <a href="javascript:;" data-bs-toggle="modal" data-bs-target="#QuickViewModal">
                                                        <i className="bi bi-zoom-in"></i>
                                                    </a>
                                                </div>
                                                <a href="product-details.html">
                                                    <img src={image} className="card-img-top" alt={`Product ${index + 1}`} />
                                                </a>
                                            </div>
                                            <div className="card-body">
                                                <div className="product-info text-center">
                                                    <h6 className="mb-1 fw-bold product-name">Product Name</h6>
                                                    <div className="ratings mb-1 h6">
                                                        <i className="bi bi-star-fill text-warning"></i>
                                                        <i className="bi bi-star-fill text-warning"></i>
                                                        <i className="bi bi-star-fill text-warning"></i>
                                                        <i className="bi bi-star-fill text-warning"></i>
                                                        <i className="bi bi-star-fill text-warning"></i>
                                                    </div>
                                                    <p className="mb-0 h6 fw-bold product-price">$49</p>
                                                </div>
                                            </div>
                                        </div> */}