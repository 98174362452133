import React from 'react'

export default function ContactUs() {
    return (
    <>
            <section class="section-padding">
                <div class="container">

                    <div class="separator mb-3">
                        <div class="line"></div>
                        <h3 class="mb-0 h3 fw-bold">Find Us Map</h3>
                        <div class="line"></div>
                    </div>

                    <div class="border p-3">
                        <iframe class="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3068133.3522730103!2d78.9629!3d20.5937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc8c43e09f17a1d%3A0x8d4673e0d42b89c1!2sIndia!5e0!3m2!1sen!2sus!4v1641494997273" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <div class="separator my-3">
                        <div class="line"></div>
                        <h3 class="mb-0 h3 fw-bold">Why Choose Us</h3>
                        <div class="line"></div>
                    </div>

                    <div class="row g-4">
                        <div class="col-xl-8">
                            <div class="p-4 border">
                                    <div class="form-body">
                                        <h4 class="mb-0 fw-bold">Drop Us a Line</h4>
                                        <div class="my-3 border-bottom"></div>
                                        <div class="mb-3">
                                            <label class="form-label">Enter Your Name</label>
                                            <input type="text" class="form-control rounded-0"/>
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Enter Email</label>
                                            <input type="text" class="form-control rounded-0"/>
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Phone Number</label>
                                            <input type="text" class="form-control rounded-0"/>
                                        </div>
                                        <div class="mb-3">
                                            <label class="form-label">Message</label>
                                            <textarea class="form-control rounded-0" rows="4" cols="4"></textarea>
                                        </div>
                                        <div class="mb-0">
                                            <a href="thank-you.html" class="btn btn-dark btn-ecomm">Send Message</a>
                                        </div>
                                    </div>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="p-3 border">
                                <div class="address mb-3">
                                    <h5 class="mb-0 fw-bold">Address</h5>
                                    <p class="mb-0 font-12">123 Street Name, City, Australia</p>
                                </div>
                                <hr/>
                                    <div class="phone mb-3">
                                        <h5 class="mb-0 fw-bold">Phone</h5>
                                        <p class="mb-0 font-13">Toll Free (123) 472-796</p>
                                        <p class="mb-0 font-13">Mobile : +91-9910XXXX</p>
                                    </div>
                                    <hr/>
                                        <div class="email mb-3">
                                            <h5 class="mb-0 fw-bold">Email</h5>
                                            <p class="mb-0 font-13">mail@example.com</p>
                                        </div>
                                        <hr/>
                                            <div class="working-days mb-0">
                                                <h5 class="mb-0 fw-bold">Working Days</h5>
                                                <p class="mb-0 font-13">Mon - FRI / 9:30 AM - 6:30 PM</p>
                                            </div>
                                        </div>
                                    </div>
                            </div>

                        </div>
                    </section>
                </>
                )
}
