import React, { useEffect } from 'react'
import Banner from './Banner'
import FeatureProduct from './FeatureProduct'
import LatestProducts from './LatestProducts'
import WhatWeOffer from './WhatWeOffer'
import NewFeaturesofTrendingProducts from './NewFeaturesofTrendingProducts'
import TopCategories from './TopCategories'
export default function Home() {

    return (
        <>

            <Banner />

            <FeatureProduct heading={"Featured Products"} subHeading={"Our Top Products Features"} />

            <NewFeaturesofTrendingProducts />
            <LatestProducts />

            <WhatWeOffer />


            {/* <section class="section-padding">
                    <div class="container">
                        <div class="text-center pb-3">
                            <h3 class="mb-0 h3 fw-bold">Shop By Brands</h3>
                            <p class="mb-0 text-capitalize">Select your favorite brands and purchase</p>
                        </div>
                        <div class="brands">
                            <div class="row row-cols-2 row-cols-lg-5 g-4">
                                <div class="col">
                                    <div class="p-3 border rounded brand-box">
                                        <div class="d-flex align-items-center">
                                            <a href="javascript:;">
                                                <img src="assets/images/brands/01.png" class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-3 border rounded brand-box">
                                        <div class="d-flex align-items-center">
                                            <a href="javascript:;">
                                                <img src="assets/images/brands/02.png" class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-3 border rounded brand-box">
                                        <div class="d-flex align-items-center">
                                            <a href="javascript:;">
                                                <img src="assets/images/brands/03.png" class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-3 border rounded brand-box">
                                        <div class="d-flex align-items-center">
                                            <a href="javascript:;">
                                                <img src="assets/images/brands/04.png" class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-3 border rounded brand-box">
                                        <div class="d-flex align-items-center">
                                            <a href="javascript:;">
                                                <img src="assets/images/brands/05.png" class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-3 border rounded brand-box">
                                        <div class="d-flex align-items-center">
                                            <a href="javascript:;">
                                                <img src="assets/images/brands/06.png" class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-3 border rounded brand-box">
                                        <div class="d-flex align-items-center">
                                            <a href="javascript:;">
                                                <img src="assets/images/brands/07.png" class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-3 border rounded brand-box">
                                        <div class="d-flex align-items-center">
                                            <a href="javascript:;">
                                                <img src="assets/images/brands/08.png" class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-3 border rounded brand-box">
                                        <div class="d-flex align-items-center">
                                            <a href="javascript:;">
                                                <img src="assets/images/brands/09.png" class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="p-3 border rounded brand-box">
                                        <div class="d-flex align-items-center">
                                            <a href="javascript:;">
                                                <img src="assets/images/brands/10.png" class="img-fluid" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

            {/* <TopCategories heading={"Top Categories"} subHeading={"Select your favorite categories and purchase"} /> */}
            {/* <section class="product-thumb-slider subscribe-banner p-5">
                    <div class="row">
                        <div class="col-12 col-lg-6 mx-auto">
                            <div class="text-center">
                                <h3 class="mb-0 fw-bold text-white">Get Latest Update by <br /> Subscribe Our Newslater</h3>
                                <div class="mt-3">
                                    <input type="text" class="form-control form-control-lg bubscribe-control rounded-0 px-5 py-3"
                                        placeholder="Enter your email" />
                                </div>
                                <div class="mt-3 d-grid">
                                    <button type="button" class="btn btn-lg btn-ecomm bubscribe-button px-5 py-3">Subscribe</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

            {/* <section class="section-padding">
                    <div class="container">
                        <div class="text-center pb-3">
                            <h3 class="mb-0 fw-bold">Latest Blog</h3>
                            <p class="mb-0 text-capitalize">Check our latest news</p>
                        </div>
                        <div class="blog-cards">
                            <div class="row row-cols-1 row-cols-lg-3 g-4">
                                <div class="col">
                                    <div class="card">
                                        <img src="assets/images/blog/01.png" class="card-img-top rounded-0" alt="..." />
                                        <div class="card-body">
                                            <div class="d-flex align-items-center gap-4">
                                                <div class="posted-by">
                                                    <p class="mb-0"><i class="bi bi-person me-2"></i>Virendra</p>
                                                </div>
                                                <div class="posted-date">
                                                    <p class="mb-0"><i class="bi bi-calendar me-2"></i>15 Aug, 2022</p>
                                                </div>
                                            </div>
                                            <h5 class="card-title fw-bold mt-3">Blog title here</h5>
                                            <p class="mb-0">Some quick example text to build on the card title and make.</p>
                                            <a href="blog-read.html" class="btn btn-outline-dark btn-ecomm mt-3">Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card">
                                        <img src="assets/images/blog/02.png" class="card-img-top rounded-0" alt="..." />
                                        <div class="card-body">
                                            <div class="d-flex align-items-center gap-4">
                                                <div class="posted-by">
                                                    <p class="mb-0"><i class="bi bi-person me-2"></i>Virendra</p>
                                                </div>
                                                <div class="posted-date">
                                                    <p class="mb-0"><i class="bi bi-calendar me-2"></i>15 Aug, 2022</p>
                                                </div>
                                            </div>
                                            <h5 class="card-title fw-bold mt-3">Blog title here</h5>
                                            <p class="mb-0">Some quick example text to build on the card title and make.</p>
                                            <a href="blog-read.html" class="btn btn-outline-dark btn-ecomm mt-3">Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card">
                                        <img src="assets/images/blog/03.png" class="card-img-top rounded-0" alt="..." />
                                        <div class="card-body">
                                            <div class="d-flex align-items-center gap-4">
                                                <div class="posted-by">
                                                    <p class="mb-0"><i class="bi bi-person me-2"></i>Virendra</p>
                                                </div>
                                                <div class="posted-date">
                                                    <p class="mb-0"><i class="bi bi-calendar me-2"></i>15 Aug, 2022</p>
                                                </div>
                                            </div>
                                            <h5 class="card-title fw-bold mt-3">Blog title here</h5>
                                            <p class="mb-0">Some quick example text to build on the card title and make.</p>
                                            <a href="blog-read.html" class="btn btn-outline-dark btn-ecomm mt-3">Read More</a>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section> */}
        </>
    )
}
