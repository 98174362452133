import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CommonLayout from './layouts/CommonLayout';
import Home from './pages/HomePage/Home';
// import Login from './pages/Login/Login1';
import Registration from './pages/Registration/Registration';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ContactUs from './pages/ContactUs/ContactUs';
import Login from './pages/Login/Login';
// import RegistrationNew from './pages/Registration/RegistrationNew';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<CommonLayout page={<Home />} />} />
        <Route path='/login/' element={<CommonLayout page={<Login />} />} />
        {/* <Route path='/login/' element={<CommonLayout page={<Login />} />} /> */}
        <Route path='/registration/' element={<CommonLayout page={<Registration />} />} />
        {/* <Route path='/registrationnew/' element={<CommonLayout page={<RegistrationNew />} />} /> */}
        <Route path='/resetpassword/' element={<CommonLayout page={<ResetPassword />} />} />
        <Route path='/contactus/' element={<CommonLayout page={<ContactUs />} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
