import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function Login() {
    let navigate = useNavigate()
    let [sendForm, setSendForm] = useState({})

    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setSendForm({ ...sendForm, [name]: value })

    }
    let handleSave = () => {
        let getData = window.sessionStorage.getItem("resgistration") ? JSON.parse(window.sessionStorage.getItem("resgistration")) : 0
        if (getData !== 0) {
            if (getData.email === sendForm.email && getData.password === sendForm.password) {
                window.sessionStorage.setItem("login", JSON.stringify({"email":sendForm.email, isLogin:1}))
                navigate("/")
                window.location.reload()
            }
            else{
                alert("Enter right email and password")
            }

        }
        else {
            alert("You are not a register user please register your self first")
        }

    }
    return (
        <>
            <section class="section-padding">
                <div className='container'>
                    <div className='d-flex flex-row justify-content-center align-items-center pb-3 registration'>
                        <h4>Login</h4>
                    </div>
                    <div class="col-12 pt-4">
                        <p class="mb-0 rounded-0 w-100 text-lightbrown">If you do not have an account with us, please sign up at the <Link to="/registration/" class="text-brown">Sign Up</Link></p>
                    </div>

                    <div className='pt-4'>
                        <div class="mb-3">
                            <label for="exampleInputEmail1" class="form-label ">Email address</label>
                            <input type="email" class="form-control" name="email" onChange={handleChange} id="exampleInputEmail1" aria-describedby="emailHelp" />
                            <div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div>
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label">Password</label>
                            <input type="password" class="form-control" name="password" onChange={handleChange} id="exampleInputPassword1" />
                        </div>

                    </div>
                    <div class="pt-2">
                        <Link to="/resetpassword/" class="text-content rounded-0 w-100 text-lightbrown"><i class="bi bi-lock me-2"></i>Forgot Password ?</Link>
                    </div>

                    <button className='btn mt-3 login-btn' onClick={handleSave}>
                        Login
                    </button>
                </div>
            </section>
        </>
    )
}
