import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <>
            <section class="footer-section bg-section-2 section-padding bg-brown">
                <div class="container">
                    <div class="row row-cols-1 row-cols-lg-4 g-4">
                        <div class="col">
                            <div class="footer-widget-6">
                                <img src="assets/images/logo-4.png" class="mb-3" width="180" alt="" />
                                <h5 class="mb-3 fw-bold text-lightgraybrown">About Us</h5>
                                <p class="mb-2 text-golden">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>

                                <a class="link-dark" href="javascript:;">Read More</a>
                            </div>
                        </div>
                        <div class="col">
                            <div class="footer-widget-7">
                                <h5 class="mb-3 fw-bold text-lightgraybrown">Explore</h5>
                                <ul class="widget-link list-unstyled text-lightgraybrown">
                                    <li><a href="javascript:;" className='text-golden'>Fashion</a></li>
                                    <li><a href="javascript:;" className='text-golden'>Women</a></li>
                                    <li><a href="javascript:;" className='text-golden'>Furniture</a></li>
                                    <li><a href="javascript:;" className='text-golden'>Shoes</a></li>
                                    <li><a href="javascript:;" className='text-golden'>Topwear</a></li>
                                    <li><a href="javascript:;" className='text-golden'>Brands</a></li>
                                    <li><a href="javascript:;" className='text-golden'>Kids</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col">
                            <div class="footer-widget-8">
                                <h5 class="mb-3 fw-bold text-lightgraybrown">Company</h5>
                                <ul class="widget-link list-unstyled text-lightgraybrown">
                                    <li><a href="javascript:;" className='text-golden'>About Us</a></li>
                                    <li><a href="javascript:;" className='text-golden'>Contact Us</a></li>
                                    <li><a href="javascript:;" className='text-golden'>FAQ</a></li>
                                    <li><a href="javascript:;" className='text-golden'>Privacy</a></li>
                                    <li><a href="javascript:;" className='text-golden'>Terms</a></li>
                                    <li><a href="javascript:;" className='text-golden'>Complaints</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col">
                            <div class="footer-widget-9">
                                <h5 class="mb-3 fw-bold text-lightgraybrown">Follow Us</h5>
                                <div class="social-link d-flex align-items-center gap-2 ">
                                    <a href="javascript:;"><i class="bi bi-facebook"></i></a>
                                    <a href="javascript:;"><i class="bi bi-twitter"></i></a>
                                    <a href="javascript:;"><i class="bi bi-linkedin"></i></a>
                                    <a href="javascript:;"><i class="bi bi-youtube"></i></a>
                                    <a href="javascript:;"><i class="bi bi-instagram"></i></a>
                                </div>
                                <div class="mb-3 mt-3">
                                    <h5 class="mb-0 fw-bold text-lightgraybrown">Support</h5>
                                    <Link to="" class="mb-0 text-muted_ text-golden">kanpack@gmail.com</Link>
                                </div>
                                <div class="">
                                    <h5 class="mb-0 fw-bold text-lightgraybrown">Toll Free</h5>
                                    <p class="mb-0 text-muted_ text-golden">1800- 8xx 2xx</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-5"></div>
                    {/* <div class="row">
                        <div class="col-12">
                            <div class="text-center">
                                <h5 class="fw-bold mb-3">Download Mobile App</h5>
                            </div>
                            <div class="app-icon d-flex flex-column flex-sm-row align-items-center justify-content-center gap-2">
                                <div>
                                    <a href="javascript:;">
                                        <img src="assets/images/play-store.png" width="160" alt="" />
                                    </a>
                                </div>
                                <div>
                                    <a href="javascript:;">
                                        <img src="assets/images/apple-store.png" width="160" alt="" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </section>
            <footer class="footer-strip text-center py-3 bg-section-2 border-top positon-absolute bottom-0 bg-lightgraybrown">
                <p class="mb-0 text-muted">© 2023. www.kanpack.com | All rights reserved.</p>
            </footer>
        </>
    )
}
