import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function Header() {
    let navigate = useNavigate()

    let [userData, setUserData] = useState({
        isLogin: 0
    })
    let getdata = () => {
        let data = window.sessionStorage.getItem("login") ? JSON.parse(window.sessionStorage.getItem("login")) : 0
        if (data !== 0) {
            setUserData({ isLogin: data.isLogin })
        }
    }
    let handleLogout = () => {
        window.sessionStorage.removeItem("login")
        window.location.reload()
        navigate("/")
    }
    useEffect(() => {
        getdata()
    }, [window.sessionStorage])
    return (
        <>
            <header className="top-header">
                <nav className="navbar navbar-expand-xl w-100 navbar-dark container container">
                    <Link className="navbar-brand d-none d-xl-inline" to="/"><span style={{color:"black"}}>KANGPACK</span></Link>
                    <a className="mobile-menu-btn d-inline d-xl-none" href="javascript:;" data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasNavbar">
                        <i className="bi bi-list"></i>
                    </a>
                    <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
                        <div className="offcanvas-header">
                            <div className="offcanvas-logo">KANGPACK
                            </div>
                            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body primary-menu">
                            <ul className="navbar-nav justify-content-start flex-grow-1 gap-1">
                                <li className="nav-item">
                                    <Link className="nav-link" to="/">Home</Link>
                                </li>
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="tv-shows.html"
                                        data-bs-toggle="dropdown">
                                        Carpet Bags
                                    </a>
                                    <div className="dropdown-menu dropdown-large-menu">
                                        <div className="row">
                                            <div className="col-12 col-xl-4">
                                                <h6 className="large-menu-title">Fashion</h6>
                                                <ul className="list-unstyled">
                                                    <li><a href="javascript:;">Casual T-Shirts</a>
                                                    </li>
                                                    <li><a href="javascript:;">Formal Shirts</a>
                                                    </li>
                                                    <li><a href="javascript:;">Jackets</a>
                                                    </li>
                                                    <li><a href="javascript:;">Jeans</a>
                                                    </li>
                                                    <li><a href="javascript:;">Dresses</a>
                                                    </li>
                                                    <li><a href="javascript:;">Sneakers</a>
                                                    </li>
                                                    <li><a href="javascript:;">Belts</a>
                                                    </li>
                                                    <li><a href="javascript:;">Sports Shoes</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-12 col-xl-4">
                                                <h6 className="large-menu-title">Electronics</h6>
                                                <ul className="list-unstyled">
                                                    <li><a href="javascript:;">Mobiles</a>
                                                    </li>
                                                    <li><a href="javascript:;">Laptops</a>
                                                    </li>
                                                    <li><a href="javascript:;">Macbook</a>
                                                    </li>
                                                    <li><a href="javascript:;">Televisions</a>
                                                    </li>
                                                    <li><a href="javascript:;">Lighting</a>
                                                    </li>
                                                    <li><a href="javascript:;">Smart Watch</a>
                                                    </li>
                                                    <li><a href="javascript:;">Galaxy Phones</a>
                                                    </li>
                                                    <li><a href="javascript:;">PC Monitors</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-12 col-xl-4 d-none d-xl-block">
                                                <div className="pramotion-banner1">
                                                    <img src="assets/images/menu-img.jpg" className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li> */}
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown">
                                        HandBags
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="cart.html">Shop Cart</a></li>
                                        <li><a className="dropdown-item" href="wishlist.html">Wishlist</a></li>
                                        <li><a className="dropdown-item" href="product-details.html">Product Details</a></li>
                                        <li><a className="dropdown-item" href="payment-method.html">Payment Method</a></li>
                                        <li><a className="dropdown-item" href="billing-details.html">Billing Details</a></li>
                                        <li><a className="dropdown-item" href="address.html">Addresses</a></li>
                                        <li><a className="dropdown-item" href="shop-grid.html">Shop Grid</a></li>
                                        <li><a className="dropdown-item" href="shop-grid-type-4.html">Shop Grid 4</a></li>
                                        <li><a className="dropdown-item" href="shop-grid-type-5.html">Shop Grid 5</a></li>
                                        <li><a className="dropdown-item" href="search.html">Search</a></li>
                                    </ul>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link" href="#">HandBags</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Laptop Bags</a>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="#">Tote Bags</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="#">Vintage Bags</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown">
                                        Category
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="#">Gym Bags</a></li>
                                        <li><a className="dropdown-item" href="#">Cross Body Bags</a></li>
                                        <li><a className="dropdown-item" href="#">Waist Bags</a></li>
                                        {/* <li><a className="dropdown-item" href="payment-method.html">Payment Method</a></li>
                                        <li><a className="dropdown-item" href="billing-details.html">Billing Details</a></li>
                                        <li><a className="dropdown-item" href="address.html">Addresses</a></li>
                                        <li><a className="dropdown-item" href="shop-grid.html">Shop Grid</a></li>
                                        <li><a className="dropdown-item" href="shop-grid-type-4.html">Shop Grid 4</a></li>
                                        <li><a className="dropdown-item" href="shop-grid-type-5.html">Shop Grid 5</a></li>
                                        <li><a className="dropdown-item" href="search.html">Search</a></li> */}
                                    </ul>
                                </li>
                                {/* <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown">
                                        Account
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="account-dashboard.html">Dashboard</a></li>
                                        <li><a className="dropdown-item" href="account-orders.html">My Orders</a></li>
                                        <li><a className="dropdown-item" href="account-profile.html">My Profile</a></li>
                                        <li><a className="dropdown-item" href="account-edit-profile.html">Edit Profile</a></li>
                                        <li><a className="dropdown-item" href="account-saved-address.html">Addresses</a></li>
                                        
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown">
                                        Blog
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li><a className="dropdown-item" href="blog-post.html">Blog Post</a></li>
                                        <li><a className="dropdown-item" href="blog-read.html">Blog Read</a></li>
                                    </ul>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    <ul className="navbar-nav secondary-menu flex-row ">

                        {/* <li className="nav-item">
                            <a className="nav-link dark-mode-icon" href="javascript:;">
                                <div className="mode-icon">
                                    <i className="bi bi-moon"></i>
                                </div>
                            </a>
                        </li> */}
                        <li className="nav-item">
                            <a className="nav-link" href="#"><i className="bi bi-search"></i></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#"><i className="bi bi-suit-heart"></i></a>
                        </li>
                        <li className="nav-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight">
                            <a className="nav-link position-relative" href="javascript:;">
                                <div className="cart-badge">8</div>
                                <i className="bi bi-basket2"></i>
                            </a>
                        </li>
                        <li className="nav-item dropdown">

                            {/* <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown">
                                Account
                            </a> */}
                            <a className="nav-link dropdown-toggle dropdown-toggle-nocaret" href="javascript:;" data-bs-toggle="dropdown"><i className="bi bi-person-circle"></i></a>
                            <ul className="dropdown-menu">
                                <li><Link className="dropdown-item" to="#"><i class="bi bi-person-fill"></i>&nbsp; Profile</Link></li>
                                {
                                    userData.isLogin === 0 ?
                                        <>

                                            <li><Link className="dropdown-item" to="/login/"><i class="bi bi-box-arrow-in-right"></i>&nbsp; Login</Link></li>
                                            <li><Link className="dropdown-item" to="/registration/"><i class="bi bi-card-checklist"></i>&nbsp; Register</Link></li>
                                        </>
                                        :
                                        <>
                                            <li><Link className="dropdown-item" to="#" onClick={handleLogout}><i class="bi bi-box-arrow-in-right"></i>&nbsp; Logout</Link></li>
                                        </>

                                }
                                <li><Link className="dropdown-item" to="/resetpassword/"><i class="bi bi-key"></i>&nbsp; Password</Link></li>
                            </ul>

                        </li>
                    </ul>
                </nav>
            </header>


            {/* add cart popup */}
            <div class="offcanvas offcanvas-end" data-bs-scroll="true" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div class="offcanvas-header bg-section-2">
                    <h5 class="mb-0 fw-bold" id="offcanvasRightLabel">8 items in the cart</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div class="cart-list">

                        <div class="d-flex align-items-center gap-3">
                            <div class="bottom-product-img">
                                <a href="product-details.html">
                                    <img src="assets/images/new-arrival/01.png" width="60" alt="" />
                                </a>
                            </div>
                            <div class="">
                                <h6 class="mb-0 fw-light mb-1">Product Name</h6>
                                <p class="mb-0"><strong>1 X $59.00</strong>
                                </p>
                            </div>
                            <div class="ms-auto fs-5">
                                <a href="javascript:" class="link-dark"><i class="bi bi-trash"></i></a>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center gap-3">
                            <div class="bottom-product-img">
                                <a href="product-details.html">
                                    <img src="assets/images/new-arrival/02.png" width="60" alt="" />
                                </a>
                            </div>
                            <div class="">
                                <h6 class="mb-0 fw-light mb-1">Product Name</h6>
                                <p class="mb-0"><strong>1 X $59.00</strong>
                                </p>
                            </div>
                            <div class="ms-auto fs-5">
                                <a href="javascript:" class="link-dark"><i class="bi bi-trash"></i></a>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center gap-3">
                            <div class="bottom-product-img">
                                <a href="product-details.html">
                                    <img src="assets/images/new-arrival/03.png" width="60" alt="" />
                                </a>
                            </div>
                            <div class="">
                                <h6 class="mb-0 fw-light mb-1">Product Name</h6>
                                <p class="mb-0"><strong>1 X $59.00</strong>
                                </p>
                            </div>
                            <div class="ms-auto fs-5">
                                <a href="javascript:" class="link-dark"><i class="bi bi-trash"></i></a>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center gap-3">
                            <div class="bottom-product-img">
                                <a href="product-details.html">
                                    <img src="assets/images/new-arrival/04.png" width="60" alt="" />
                                </a>
                            </div>
                            <div class="">
                                <h6 class="mb-0 fw-light mb-1">Product Name</h6>
                                <p class="mb-0"><strong>1 X $59.00</strong>
                                </p>
                            </div>
                            <div class="ms-auto fs-5">
                                <a href="javascript:" class="link-dark"><i class="bi bi-trash"></i></a>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center gap-3">
                            <div class="bottom-product-img">
                                <a href="product-details.html">
                                    <img src="assets/images/new-arrival/05.png" width="60" alt="" />
                                </a>
                            </div>
                            <div class="">
                                <h6 class="mb-0 fw-light mb-1">Product Name</h6>
                                <p class="mb-0"><strong>1 X $59.00</strong>
                                </p>
                            </div>
                            <div class="ms-auto fs-5">
                                <a href="javascript:" class="link-dark"><i class="bi bi-trash"></i></a>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center gap-3">
                            <div class="bottom-product-img">
                                <a href="product-details.html">
                                    <img src="assets/images/new-arrival/06.png" width="60" alt="" />
                                </a>
                            </div>
                            <div class="">
                                <h6 class="mb-0 fw-light mb-1">Product Name</h6>
                                <p class="mb-0"><strong>1 X $59.00</strong>
                                </p>
                            </div>
                            <div class="ms-auto fs-5">
                                <a href="javascript:" class="link-dark"><i class="bi bi-trash"></i></a>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center gap-3">
                            <div class="bottom-product-img">
                                <a href="product-details.html">
                                    <img src="assets/images/new-arrival/07.png" width="60" alt="" />
                                </a>
                            </div>
                            <div class="">
                                <h6 class="mb-0 fw-light mb-1">Product Name</h6>
                                <p class="mb-0"><strong>1 X $59.00</strong>
                                </p>
                            </div>
                            <div class="ms-auto fs-5">
                                <a href="javascript:" class="link-dark"><i class="bi bi-trash"></i></a>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center gap-3">
                            <div class="bottom-product-img">
                                <a href="product-details.html">
                                    <img src="assets/images/new-arrival/08.png" width="60" alt="" />
                                </a>
                            </div>
                            <div class="">
                                <h6 class="mb-0 fw-light mb-1">Product Name</h6>
                                <p class="mb-0"><strong>1 X $59.00</strong>
                                </p>
                            </div>
                            <div class="ms-auto fs-5">
                                <a href="javascript:" class="link-dark"><i class="bi bi-trash"></i></a>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center gap-3">
                            <div class="bottom-product-img">
                                <a href="product-details.html">
                                    <img src="assets/images/new-arrival/09.png" width="60" alt="" />
                                </a>
                            </div>
                            <div class="">
                                <h6 class="mb-0 fw-light mb-1">Product Name</h6>
                                <p class="mb-0"><strong>1 X $59.00</strong>
                                </p>
                            </div>
                            <div class="ms-auto fs-5">
                                <a href="javascript:" class="link-dark"><i class="bi bi-trash"></i></a>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex align-items-center gap-3">
                            <div class="bottom-product-img">
                                <a href="product-details.html">
                                    <img src="assets/images/new-arrival/10.png" width="60" alt="" />
                                </a>
                            </div>
                            <div class="">
                                <h6 class="mb-0 fw-light mb-1">Product Name</h6>
                                <p class="mb-0"><strong>1 X $59.00</strong>
                                </p>
                            </div>
                            <div class="ms-auto fs-5">
                                <a href="javascript:" class="link-dark"><i class="bi bi-trash"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="offcanvas-footer p-3 border-top">
                    <div class="d-grid">
                        <button type="button" class="btn btn-lg btn-dark btn-ecomm px-5 py-3">Checkout</button>
                    </div>
                </div>

            </div>
        </>
    )
}
