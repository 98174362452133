import React from 'react'
import s1 from "../../assets/images/sliders/s_1.png"
import s2 from "../../assets/images/sliders/s_2.png"
import s3 from "../../assets/images/featured-products/s2.jpeg"
import s4 from "../../assets/images/featured-products/s1.jpeg"
import s5 from "../../assets/images/featured-products/s2.jpeg"
export default function Banner() {

    let arr = [s1, s2, s3, s4, s5]
    return (
        <>
            <section class="slider-section">
                <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel" >
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
                            aria-current="true"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4"></button>
                    </div>
                    <div class="carousel-inner" >
                        {
                            arr && arr.map((val, ind) => {
                                if(ind===0)
                                {
                                    return(
                                        <div class="carousel-item active bg-lightbrown position-relative" style={{ height: "700px" }}>
                                        <div class="row d-flex align-items-center" >

                                            <div class="carousel-caption_ d-none_ d-lg-flex justify-content-center align-items-center ms-3  position-absolute h-100 w-100" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
                                                <div class="">
                                                    <h3 class="h3 fw-light text-white fw-bold">Amazing combination of style, </h3>
                                                    <h1 class="h1 text-white fw-bold">beauty and latest trends.</h1>
                                                    <p class="text-white fw-bold"><i>Welcome to the number 1 company in the industry! Looking for some helpful advice? </i></p>
                                                    <div class=""><a class="btn btn-dark btn-ecomm" href="shop-grid.html">Shop Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <img src={val} class="img-fluid" alt="..." style={{ height: "700px" }} />

                                        </div>
                                    </div>
                                    )
                                }
                                else{
                                    return (
                                    <div class="carousel-item  bg-lightbrown position-relative" style={{ height: "700px" }}>
                                        <div class="row d-flex align-items-center" >

                                            <div class="carousel-caption_ d-none_ d-lg-flex justify-content-center align-items-center ms-3 position-absolute h-100 w-100" style={{ backgroundColor: "rgba(0,0,0,0.5)" }}>
                                                <div class="">
                                                    <h3 class="h3 fw-light text-white fw-bold">Amazing combination of style, </h3>
                                                    <h1 class="h1 text-white fw-bold">beauty and latest trends.</h1>
                                                    <p class="text-white fw-bold"><i>Welcome to the number 1 company in the industry! Looking for some helpful advice? </i></p>
                                                    <div class=""><a class="btn btn-dark btn-ecomm" href="shop-grid.html">Shop Now</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <img src={val} class="img-fluid" alt="..." style={{ height: "700px" }} />

                                        </div>
                                    </div>
                                )
                                }
                                
                            })
                        }

                        {/* <div class="carousel-item bg-lightbrown position-relative" style={{ height: "700px" }}>
                            <div class="row d-flex align-items-center">
                                <div class="carousel-caption_ d-none_ d-lg-flex justify-content-center align-items-center pb-5 mb-5 position-absolute h-100 w-100" style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
                                    <div class="">
                                        <h3 class="h3 fw-light text-white fw-bold">THE LARGEST</h3>
                                        <h1 class="h1 text-white fw-bold">RANGE OF HANDBAGS</h1>
                                        <p class="text-white fw-bold"><i>Welcome to the number 1 company in the industry! Looking for some helpful advice? </i></p>
                                        <div class=""> <a class="btn btn-dark btn-ecomm" href="shop-grid.html">Shop Now</a>
                                        </div>
                                    </div>
                                </div>
                                <img src={s2} class="img-fluid" alt="..." style={{ height: "700px" }} />

                            </div>
                        </div>
                        <div class="carousel-item bg-lightbrown position-relative" style={{ height: "700px" }}>
                            <div class="row d-flex align-items-center">
                                <div class="carousel-caption_ d-none_ d-lg-flex justify-content-center align-items-center pb-5 mb-5 position-absolute h-100 w-100" style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
                                    <div class="">
                                        <h3 class="h3 fw-light text-white fw-bold">A tremendous collection</h3>
                                        <h1 class="h1 text-white fw-bold">of designer bags</h1>
                                        <p class="text-white fw-bold"><i>Welcome to the number 1 company in the industry! Looking for some helpful advice?</i></p>
                                        <div class=""><a class="btn btn-dark btn-ecomm" href="shop-grid.html">Shop Now</a>
                                        </div>
                                    </div>
                                </div>
                                <img src={s3} class="img-fluid" alt="..." style={{ height: "700px" }} />

                            </div>
                        </div>
                        <div class="carousel-item bg-lightbrown position-relative" style={{ height: "700px" }}>
                            <div class="row d-flex align-items-center">
                                <div class="carousel-caption_ d-none_ d-lg-flex justify-content-center align-items-center pb-5 mb-5 position-absolute h-100 w-100" style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
                                    <div class="">
                                        <h3 class="h3 fw-light text-white fw-bold">Latest Trending</h3>
                                        <h1 class="h1 text-white fw-bold">Get Latest Fashionable Bags</h1>
                                        <p class="text-white fw-bold"><i>Welcome to the number 1 company in the industry! Looking for some helpful advice?</i></p>
                                        <div class=""><a class="btn btn-dark btn-ecomm" href="shop-grid.html">Shop Now</a>
                                        </div>
                                    </div>
                                </div>
                                <img src={s4} class="img-fluid" alt="..." style={{ height: "700px" }} />

                            </div>
                        </div>
                        <div class="carousel-item bg-lightbrown position-relative" style={{ height: "700px" }}>
                            <div class="row d-flex align-items-center">
                                <div class="carousel-caption_ d-none_ d-lg-flex justify-content-center align-items-center pb-5 mb-5 position-absolute h-100 w-100" style={{ backgroundColor: "rgba(0,0,0,0.3)" }}>
                                    <div class="">
                                        <h3 class="h3 fw-light text-white fw-bold">Super Deals</h3>
                                        <h1 class="h1 text-white fw-bold">Vintage Bags</h1>
                                        <p class="text-white fw-bold"><i>Welcome to the number 1 company in the industry! Looking for some helpful advice?</i></p>
                                        <div class=""><a class="btn btn-dark btn-ecomm" href="shop-grid.html">Shop Now</a>
                                        </div>
                                    </div>
                                </div>
                                <img src={s5} class="img-fluid" alt="..." style={{ height: "700px" }} />

                            </div>
                        </div> */}
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </section>
        </>
    )
}
