import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

export default function Registration() {
    let navigate = useNavigate()
    let [sendForm, setSendForm] = useState({})
    let handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setSendForm({ ...sendForm, [name]: value })

    }
    let handleSave = () => {
        window.sessionStorage.setItem("resgistration", JSON.stringify(sendForm))
        navigate("/login/")
    }
    return (
        <>
            <section className="section-padding">
                <div className='container'>
                    <div className='d-flex flex-row justify-content-center align-items-center pb-3 registration'>
                        <h4>REGISTER ACCOUNT</h4>
                    </div>
                    <div className="col-12 pt-4">
                        <p className="mb-0 rounded-0 w-100 text-lightbrown">If you already have an account with us, please login at the <Link to="/login/" className="text-brown">Login</Link></p>
                    </div>

                    <div className='pt-4'>
                        <h5>Your Personal Details</h5>
                        <hr />

                        <div className='row mt-3'>
                            <div className='col-md-2 col-0'>

                            </div>
                            <span for="exampleInput" className="form-label col-md-2 col-12" style={{ width: "170px" }}>First Name  *</span>
                            <div className='col-md-8 md-12'>
                                <input type="text" className="form-control" name="fname" onChange={handleChange} id="exampleInput" aria-describedby="Help" />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-2 col-0'>

                            </div>
                            <span for="exampleInput" className="form-label col-md-2 col-12" style={{ width: "170px" }}>Last Name  *</span>
                            <div className='col-md-8 col-12'>
                                <input type="text" className="form-control" name="lname" onChange={handleChange} id="exampleInput" aria-describedby="Help" />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-2 col-0'>

                            </div>
                            <span for="exampleInput" className='col-md-2 col-12' style={{ width: "170px" }}>Email  *</span>
                            <div className='col-md-8 col-12'>
                                <input type="email" className="form-control" name="email" onChange={handleChange} id="exampleInputEmail1" aria-describedby="emailHelp" />
                                <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-2 col-0'>

                            </div>
                            <span for="exampleInput" className='col-md-2 col-12' style={{ width: "170px" }}>Phone Number 1  *</span>
                            <div className='col-md-8 col-12'>
                                <input type="text" className="form-control" name="phone1" onChange={handleChange} id="exampleInput" aria-describedby="Help" />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-2 col-0'>

                            </div>
                            <span for="exampleInput" className='col-md-2 col-12' style={{ width: "170px" }}>Phone Number 2  *</span>
                            <div className='col-md-8 col-12'>
                                <input type="text" className="form-control" name="phone2" onChange={handleChange} id="exampleInput" aria-describedby="Help" />
                            </div>
                        </div>
                    </div>

                    <div className='pt-4'>
                        <h5>Your Address</h5>
                        <hr />
                        <div className='row mt-3'>
                            <div className='col-md-2 col-0'>

                            </div>
                            <span for="exampleInput" className='col-md-2 col-12' style={{ width: "170px" }}>Address 1  *</span>
                            <div className='col-md-8 col-12'>
                                <input type="text" className="form-control" name="address1" onChange={handleChange} id="exampleInput" aria-describedby="Help" />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-2 col-0'>

                            </div>
                            <span for="exampleInput" className='col-md-2 col-12' style={{ width: "170px" }}>Address 2  *</span>
                            <div className='col-md-8 col-12'>
                                <input type="text" className="form-control" name="address2" onChange={handleChange} id="exampleInput" aria-describedby="Help" />
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-2 col-0'>

                            </div>
                            <span for="exampleInput" className='col-md-2 col-12' style={{ width: "170px" }}>Country  *</span>
                            <div className='col-md-8 col-12'>
                                <select className="form-select" name="country" onChange={handleChange}>
                                    <option>Select Country</option>
                                </select>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-2 col-0'>

                            </div>
                            <span for="exampleInput" className='col-md-2 col-12' style={{ width: "170px" }}>State  *</span>
                            <div className='col-md-8 col-12'>
                                <select className="form-select" name="state" onChange={handleChange}>
                                    <option>Select State</option>
                                </select>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-2 col-0'>

                            </div>
                            <span for="exampleInput" className='col-md-2 col-12' style={{ width: "170px" }}>City  *</span>
                            <div className='col-md-8 col-12'>
                                <select className="form-select" name="city" onChange={handleChange}>
                                    <option>Select City</option>
                                </select>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-2 col-0'>

                            </div>
                            <span for="exampleInput" className='col-md-2 col-12' style={{ width: "170px" }}>Pin Code  *</span>
                            <div className='col-md-8 col-12'>
                                <input type="text" className="form-control" name="pincode" onChange={handleChange} id="exampleInput" aria-describedby="Help" />
                            </div>
                        </div>
                    </div>

                    <div className='pt-4'>
                        <h5>Your Password</h5>
                        <hr />
                        <div className='d-flex flex-column'>
                            <div className='row'>
                                <div className='col-md-2 col-0'>

                                </div>
                                <span for="exampleInput" className='col-md-2 col-12' style={{ width: "170px" }}>Password  *</span>
                                <div className='col-md-8 col-12'>
                                    <input type="text" className="form-control" name="password" onChange={handleChange} id="exampleInput" aria-describedby="Help" />
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-2 col-0'>

                                </div>
                                <span for="exampleInput" className='col-md-2 col-12' style={{ width: "170px" }}>Password Confirm *</span>
                                <div className='col-md-8 col-12'>
                                    <input type="text" className="form-control" name="confirmpassword" onChange={handleChange} id="exampleInput" aria-describedby="Help" />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='pt-3 '>
                        I have read and agree to the <Link to="" className='text-brown'>Privacy Policy</Link>
                    </div>
                    <button className='btn mt-3 login-btn' onClick={handleSave}>
                        Sign Up
                    </button>
                </div>
            </section>
        </>
    )
}
