import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

export default function CommonLayout(props) {
    return (
        <>
            <Header />
            <div class="page-content">
                {props.page}
            </div>
            <Footer />
        </>
    )
}
